import { Fragment } from 'react';
import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer, TextContainer } from 'components/UI/Containers';
import { HeadingBig, HeadingSmall } from 'components/UI/Headings';

const FaqsPageTemplate = ({ data, pageContext }) => {
  const { datoCmsFaqsPage: pageData } = data;
  const { title, seo, faqList } = pageData;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <div>
            <TextContainer>
              {faqList.map(({ id, question, answer }) => (
                <Fragment key={id}>
                  <HeadingSmall as="h3">{question}</HeadingSmall>
                  <StructuredText data={answer?.value} />
                </Fragment>
              ))}
            </TextContainer>

            {/* TODO: paste animation below */}
            {/* <div></div> */}
          </div>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default FaqsPageTemplate;

export const query = graphql`
  query FaqsPageQuery($locale: String!) {
    datoCmsFaqsPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      faqList {
        id
        question
        answer {
          value
        }
      }
    }
  }
`;
